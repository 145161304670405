<template>
  <nav class="cta-buttons" aria-label="pagination links">
    <div class="section__inner grid">
      <div class="text-left">
        <ButtonIcon
          v-if="previous"
          icon="chevron-left"
          class="btn--secondary"
          :href="previous.href"
          data-test="cta-button-previous"
        >
          {{ previous.title | capitalize }}
        </ButtonIcon>
      </div>
      <div class="text-right">
        <ButtonIcon
          v-if="next"
          icon="chevron-right"
          class="btn--secondary"
          :href="next.href"
          data-test="cta-button-next"
        >
          {{ next.title | capitalize }}
        </ButtonIcon>
      </div>
    </div>
  </nav>
</template>

<script>
import { ButtonIcon } from '@unimelb/pattern-lib-vue';

export default {
  name: 'CtaButtons',
  components: {
    ButtonIcon,
  },
  props: {
    next: {
      type: [Object, Boolean],
      default: false,
    },
    previous: {
      type: [Object, Boolean],
      default: false,
    },
  },
};
</script>

<style lang="postcss">
.cta-buttons {
  border-top: 1px solid var(--col-grey-dark-25);
  background-color: var(--col-bg-alt);

  .section__inner {
    display: flex;
    min-height: 0;
    padding: 1rem;
    row-gap: 1rem;

    @media (--bp-tablet) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
</style>
